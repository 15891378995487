$text_color:#FFFFFF;
.link{
    &__text{
        color:$text_color;
        text-decoration: none;
        text-transform: uppercase;
        &_active{
            border-bottom: 2px solid #40E1EC;
            display: block;
        }
    }
    @media (min-width: 320px) and (max-width: 1279px){
        max-width: 90px;
    }
}