.ServicePage{
  max-width: 1290px;
  margin: 150px auto;
  display: flex;
  justify-content: space-between;
  &__img{
    z-index: 6;
    position: absolute;
    top: 170px;
    right: 50px;
    display: none;
    &_visible{
      display: block;
    }
  }
  &-btn{
    display: none;
    padding: 10px 20px;
    border: 1px solid #2D2E43;
    border-radius: 4px;
    background-color: inherit;
  }
  &-menu{
    max-width: 378px;
    &__mobile{
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-top: 200px;
      padding: 0 100px 200px 100px;
      background-color: white;
    }
    &__link{
      text-decoration: none;
      color: #000000;
      &_active{
        color: #40E1EC;
      }
    }
    &__item{
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }
  &-container{
    max-width: 850px;
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    max-width: 1170px;
    margin: 100px auto;
    &-menu {
      max-width: 370px;
    }
    &-container{
      max-width: 766px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px){
    max-width: 940px;
    &-menu {
      max-width: 300px;
    }
    &-container{
      max-width: 620px;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px){
    margin: 100px auto;
    max-width: 640px;
    display: block;
    &-btn{
      margin-bottom: 30px;
      display: block;
    }
    &-menu {
      display: none;
    }
    &-container{
      max-width: 620px;
    }
  }
  @media (min-width: 560px) and (max-width: 767px){
    margin: 50px auto;
    max-width: 525px;
    display: block;
    &-btn{
      margin-bottom: 20px;
      display: block;
    }
    &-menu {
      display: none;
      &__mobile{
        margin-top: 140px;
        padding-top: 100px;
      }
    }
    &-container{
      max-width: 620px;
    }
  }
  @media (min-width: 375px) and (max-width: 559px){
    margin: 50px auto;
    max-width: 335px;
    display: block;
    &-btn{
      margin-bottom: 20px;
      display: block;
    }
    &__img{
      width: 20px;
      height: 20px;
    }
    &-menu {
      display: none;
      &__mobile{
        margin-top: 140px;
        padding: 100px 40px 200px 40px;
      }
    }
    &-container{
      max-width: 620px;
    }
  }
}