$bg_color:#F8F8F8;

.projects-card {
    width: 410px;
    height: 356px;
    box-shadow: 7px 4px 15px -2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: $bg_color;

    &__img {
        width: 100%;
        height: 227px;
        margin-bottom: 30px;
        object-fit: cover;
    }

    &__title {
        text-align: center;
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        width: 370px;

        &__img {
            height: 233px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        width: 300px;
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        width: 200px;
        height: 310px;

        &__img {
            height: 174px;
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        width: 165px;
        height: 252px;

        &__img {
            height: 132px;
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width:320px) and (max-width:559px) {
        width: 335px;
        height: 250px;

        &__img {
            height: 177px;
            margin-bottom: 10px;
        }
    }
}