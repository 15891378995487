.header-container{
  background-color: #2D2E43;
  .header{
    max-width: 1290px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    .mobileMenuBurger{
      margin-right: 15px;
      align-self: center;
      width: 30px;
      height: 100px;
      display: none;
      flex-direction: column;
      justify-content: center;
      &__item{
        margin-top: 10px;
        width: 30px;
        height: 2px;
        background-color: #40E1EC;
        &:first-child{
          margin-top: 0;
        }
      }
    }
    &__logo{
      max-width: 330px;
      max-height: 150px;
    }
    .content{
      display: flex;
      flex-direction: column;
      .contacts{
        align-self: end;
        display: flex;
        flex-direction: column;
        &__phone{
          color: white;
          text-decoration: none;
          align-self: end;
        }
        &__address{
          margin-top: 8px;
          text-decoration: none;
          color: white;
        }
      }
      &__line{
        margin-top: 20px;
        width: 100%;
        height: 1px;
        background-color: #40E1EC;
      }
      .navbar{
        margin-top: 20px;
        display: flex;
        &__links{
          margin-right: 50px;
          display: flex;
          align-items: center;
          &_item{
            margin-left: 30px;
            &:first-child{
              margin-left: 0;
            }
          }
        }
      }
    }

  }
  .mobileMenu-container{
    z-index: 7;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background: rgba(0,0,0,0.5);
    &-closed{
      animation: mobileMenuContainerAnimationClose 0.5s ease;
      opacity: 0;
      pointer-events: none;
    }
    &-noValue{
      opacity: 0;
      pointer-events: none;
    }
    .mobileMenu{
      max-width: 400px;
      padding: 50px;
      top: 0;
      background-color: #2D2E43;
      &-closed{
        animation: mobileMenuAnimationClose 0.5s ease;
      }
      &-opened{
        animation: mobileMenuAnimationOpen 0.5s ease;
      }
      &__close{
        top: 35px;
        left: 350px;
        position: absolute;
        margin-right: 20px;
      }
      &-navbar{

        &__links{
          &_item{
            margin-top: 10px;
          }
        }
      }
      &-contacts{
        margin-bottom: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        &-phone{
          &__description{
            font-weight: 300;
          }
          margin-top: 5px;
          color: white;
          text-decoration: none;
        }
        &__address{
          font-weight: 300;
          margin-top: 5px;
          text-decoration: none;
          color: white;
        }
        &__email{
          font-weight: 300;
          margin-top: 5px;
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  @keyframes mobileMenuAnimationOpen {
    0%{
      transform: translate(-100%);
      opacity: 0;
    }
    100%{
      opacity: 1;
      transform: translate(0%);
    }
  }
  @keyframes mobileMenuAnimationClose {
    0%{
      opacity: 1;
      transform: translate(0%);
    }
    100%{
      opacity: 0;
      transform: translate(-100%);
    }
  }
  @keyframes mobileMenuContainerAnimationClose {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
  @media (min-width: 1440px) and (max-width: 1560px){
    .header{
      max-width: 1290px;
    }
  }
  @media (min-width: 1280px) and (max-width: 1439px){
    .header{
      max-width: 1170px;
      &__logo{
        max-width: 276px;
      }
      .navbar{
        &__links{
          margin-right: 32px;
          &_item{
            margin-left: 25px;
          }
        }
      }
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px){
    .header{
      justify-content: start;
      max-width: 940px;
      .mobileMenuBurger{
        display: flex;
      }
      &__logo{
        max-width: 200px;
      }
      .content{
        display: none;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px){
    .header{
      justify-content: start;
      max-width: 640px;
      .mobileMenuBurger{
        display: flex;
      }
      &__logo{
        max-width: 200px;
      }
      .content{
        display: none;
      }
    }
  }
  @media (min-width: 560px) and (max-width: 767px){
    .header{
      justify-content: start;
      max-width: 525px;
      .mobileMenuBurger{
        display: flex;
      }
      &__logo{
        max-width: 200px;
      }
      .content{
        display: none;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 559px){
    .header{
      justify-content: start;
      max-width: 335px;
      .mobileMenuBurger{
        display: flex;
      }

      &__logo{
        max-width: 200px;
      }
      .content{
        display: none;
      }
    }
    .mobileMenu-container{
      .mobileMenu{
        max-width: 370px;
        &__close{
          left: 325px;
        }
      }
    }
  }
}
