.directive {
    margin-top: 150px;
    margin-bottom: 184px;

    &__content {
        max-width: 1290px;
        margin: 0 auto;
        position: relative;
    }

    &__title {
        text-transform: uppercase;
        transform: rotate(270deg);
        position: absolute;
        color: #40E1EC;
        left: -230px;
        top: 221px;
        line-height: 96px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 547px);
        grid-template-rows: repeat(4, 160px);
        row-gap: 30px;
        column-gap: 86px;
        margin-left: auto;
        width: 1180px;
    }

    &__img {
        position: absolute;
        bottom: 0;
        left: 30px;
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        margin-bottom: 194px;

        &__content {
            max-width: 1170px;
        }

        &__title {
            left: -200px;
            top: 190px;
            line-height: 82px;
        }

        &__container {
            grid-template-columns: repeat(2, 500px);
            grid-template-rows: repeat(4, 160px);
            column-gap: 70px;
            width: 1070px;
        }

        &__img {
            left: 20px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        margin-top: 100px;
        margin-bottom: 144px;

        &__content {
            max-width: 940px;
        }

        &__title {
            left: -170px;
            top: 158px;
            line-height: 68px;
        }

        &__container {
            grid-template-columns: repeat(2, 420px);
            grid-template-rows: repeat(4, 160px);
            gap: 20px;
            width: 860px;

            img {
                right: -50px;
            }

            div {
                &:last-child {
                    img {
                        right: -150px;
                    }
                }
            }
        }

        &__img {
            width: 24px;
            left: 15px;
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        margin: 100px 0;

        &__content {
            max-width: 640px;
        }

        &__title {
            left: -174px;
            top: 158px;
            line-height: 68px;
        }

        &__container {
            column-gap: 20px;
            row-gap: 10px;
            grid-template-columns: repeat(2, 285px);
            grid-template-rows: repeat(4, 160px);
            width: 590px;

            img {
                right: -125px;
            }

            div {
                &:last-child {
                    h4 {
                        max-width: 290px;
                    }

                    img {
                        right: -260px;
                    }
                }
            }
        }

        &__img {
            width: 24px;
            left: 10px;
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        margin: 50px 0;

        &__content {
            max-width: 525px;
        }

        &__title {
            left: -137px;
            top: 127px;
            line-height: 55px;
        }

        &__container {
            column-gap: 30px;
            row-gap: 10px;
            grid-template-columns: repeat(2, 225px);
            grid-template-rows: repeat(4, 100px);
            width: 480px;

            img {
                right: -85px;
            }

            div {
                &:last-child {
                    h4 {
                        max-width: 290px;
                    }

                    img {
                        right: -120px;
                    }
                }
            }
        }

        &__img {
            width: 14px;
            left: 10px;
        }
    }

    @media screen and (min-width:320px) and (max-width:559px) {
        margin: 50px 0;

        &__content {
            max-width: 335px;
        }

        &__container {
            row-gap: 10px;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(7, 100px);
            width: 290px;

            img {
                right: -20px;
            }

            div {
                &:last-child {
                    h4 {
                        max-width: 200px;
                    }

                    img {
                        right: -230px;
                    }
                }
            }
        }

        &__title {
            left: -137px;
            top: 127px;
            line-height: 55px;
        }

        &__img {
            width: 14px;
            left: 10px;
        }
    }
}