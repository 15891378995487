.investments-page {
  max-width: 1290px;
  margin: 150px auto;

  &-title {

    display: flex;
    justify-content: space-between;
    align-items: center;

    &-content {
      max-width: 830px;

      &__main {
        color: #40E1EC;
        text-transform: uppercase;
      }

      &__secondary {
        color: white;
        text-shadow: -0 -1px 0 #40E1EC,
        0 -1px 0 #40E1EC,
        -0 1px 0 #40E1EC,
        0 1px 0 #40E1EC,
        -1px -0 0 #40E1EC,
        1px -0 0 #40E1EC,
        -1px 0 0 #40E1EC,
        1px 0 0 #40E1EC,
        -1px -1px 0 #40E1EC,
        1px -1px 0 #40E1EC,
        -1px 1px 0 #40E1EC,
        1px 1px 0 #40E1EC,
        -1px -1px 0 #40E1EC,
        1px -1px 0 #40E1EC,
        -1px 1px 0 #40E1EC,
        1px 1px 0 #40E1EC;
        text-transform: uppercase;
      }
    }

    &-cubes {
      &__item {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1439px) {
    max-width: 1170px;

  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    margin: 100px auto;
    max-width: 940px;
    &-title{
      &-content{
        max-width: 670px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 100px auto;
    max-width: 640px;
    &-title{
      &-content{
        max-width: 550px;
      }
      &-cubes{
        &__item{
          width: 23px;
          margin-left: 8px;
        }
      }
    }
  }
  @media (min-width: 560px) and (max-width: 767px) {
    margin: 50px auto;
    max-width: 525px;
    &-title{
      &-content{
        max-width: 450px;
      }
      &-cubes{
        &__item{
          width: 14px;
          margin-left: 5px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 559px) {
    margin: 50px auto;
    max-width: 335px;
    &-title{
      &-content{
        &__main{
          font-size: 30px !important;
        }
        &__secondary{
          font-size: 30px !important;
        }

        max-width: 335px;
      }
      &-cubes{
        display: none;
      }
    }
  }
}




