.button {
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: #40E1EC;
  color: #2D2E43;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  &:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &_black{
    background-color: inherit;
    border: 1px solid #2D2E43;
    color: #2D2E43;
  }
  &_white{
    border: 1px solid #FFFFFF;
    color: white;
    background: none;
    padding: 10px 20px;
  }
  &_disabled{
    background: #ECECEC;
    pointer-events: none;
  }
}