.clients {
    margin: 150px 0;

    &__content {
        max-width: 1290px;
        margin: 0 auto;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 112px !important;
        line-height: 153px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: white;
        text-shadow:
            -0 -1px 0 #40E1EC,
            0 -1px 0 #40E1EC,
            -0 1px 0 #40E1EC,
            0 1px 0 #40E1EC,
            -1px -0 0 #40E1EC,
            1px -0 0 #40E1EC,
            -1px 0 0 #40E1EC,
            1px 0 0 #40E1EC,
            -1px -1px 0 #40E1EC,
            1px -1px 0 #40E1EC,
            -1px 1px 0 #40E1EC,
            1px 1px 0 #40E1EC,
            -1px -1px 0 #40E1EC,
            1px -1px 0 #40E1EC,
            -1px 1px 0 #40E1EC,
            1px 1px 0 #40E1EC;
    }

    &__subtitle {
        color: #40E1EC;
        text-transform: uppercase;
        line-height: 96px;
        position: relative;
        top: -40px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-template-rows: repeat(4, 1fr);
        align-items: center;
        justify-items: center;
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        &__content {
            max-width: 1170px;
        }

        &__title {
            font-size: 94px !important;
            line-height: 128px;
            letter-spacing: -0.02em;
        }

        &__subtitle {
            line-height: 82px;
            top: -40px;
        }

        &__container {
            img {
                width: 195px;
            }
        }

        &__cub {
            height: 24px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        margin: 100px 0;

        &__content {
            max-width: 940px;
        }

        &__title {
            font-size: 78px !important;
            line-height: 107px;
        }

        &__subtitle {
            line-height: 68px;
            top: -34px;
        }

        &__cub {
            height: 30px;
        }

        &__container {
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

            img {
                width: 165px;
            }
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        margin: 100px 0;

        &__content {
            max-width: 640px;
        }

        &__title {
            font-size: 78px !important;
            line-height: 107px;
        }

        &__subtitle {
            line-height: 68px;
            top: -34px;
        }

        &__cub {
            height: 30px;
        }

        &__container {
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));

            img {
                width: 120px;
            }
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        margin: 50px 0;

        &__content {
            max-width: 525px;
        }

        &__title {
            font-size: 48px !important;
            line-height: 55px;
        }

        &__subtitle {
            line-height: 41px;
            top: -12px;
            font-size: 30px !important;
        }

        &__cub {
            height: 18px;
        }

        &__container {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

            img {
                width: 120px;
            }
        }
    }

    @media screen and (min-width:320px) and (max-width:559px) {
        margin: 50px 0;

        &__content {
            max-width: 335px;
        }

        &__title {
            font-size: 48px !important;
            line-height: 55px;
        }

        &__subtitle {
            line-height: 41px;
            top: -12px;
            font-size: 30px !important;
        }

        &__cub {
            height: 16px;
        }

        &__container {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            row-gap: 10px;

            img {
                width: 100px;
            }
        }
    }
}