$text_color:#40E1EC;
$border_color:#2D2E43;
$bg_color:#F8F8F8;

@mixin transform {
    text-transform: uppercase;
    transform: rotate(270deg);
    position: absolute;
}

.benefits {
    margin-bottom: 223px;

    &__content {
        max-width: 1290px;
        margin: 0 auto;
        position: relative;
        height: 1046px;
    }

    &__title {
        @include transform;
        color: $text_color;
        left: -265px;
        top: 263px;
        line-height: 96px;
    }

    &__subtitle {
        @include transform;
        line-height: 96px;
        color: white;
        text-shadow:
            -0 -1px 0 #40E1EC,
            0 -1px 0 #40E1EC,
            -0 1px 0 #40E1EC,
            0 1px 0 #40E1EC,
            -1px -0 0 #40E1EC,
            1px -0 0 #40E1EC,
            -1px 0 0 #40E1EC,
            1px 0 0 #40E1EC,
            -1px -1px 0 #40E1EC,
            1px -1px 0 #40E1EC,
            -1px 1px 0 #40E1EC,
            1px 1px 0 #40E1EC,
            -1px -1px 0 #40E1EC,
            1px -1px 0 #40E1EC,
            -1px 1px 0 #40E1EC,
            1px 1px 0 #40E1EC;
        left: -185px;
        top: 263px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 520px);
        grid-template-rows: repeat(6, 149px);
        margin-left: 220px;
        gap: 30px;
    }

    &__card {
        border-radius: 4px;
        background: $bg_color;
        border-left: 80px solid $border_color;

        &:nth-child(1) {
            grid-row: span 2;
        }

        &:nth-child(2) {
            grid-row: span 2;
        }

        &:nth-child(3) {
            grid-row: span 4;
        }

        &:nth-child(4) {
            grid-row: span 2;
            border-color: $text_color;
        }
    }

    &__cub {
        position: absolute;
        left: 80px;
        bottom: 0;
    }

    .card {
        &__title {
            margin-top: 50px;
            margin-bottom: 30px;
            margin-left: 20px;
        }

        &__subtitle {
            margin-left: 20px;
        }

        &__list {
            margin-top: 20px;
        }

        &__list-item {
            margin-left: 50px;
            margin-bottom: 5px;
        }
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        &__content {
            max-width: 1170px;
            height: 786px;
        }

        &__container {
            grid-template-columns: repeat(2, 470px);
            grid-template-rows: repeat(6, 106px);
            margin-left: 200px;
        }

        &__title {
            left: -235px;
            top: 225px;
            line-height: 82px;
        }

        &__subtitle {
            line-height: 82px;
            left: -170px;
            top: 225px;
        }

        &__card {
            border-left: 40px solid $border_color;

            &:nth-child(5) {
                padding-top: 7px;
            }
        }

        &__cub {
            left: 50px;
        }

        .card {
            &__title {
                margin-top: 30px;
                margin-bottom: 20px;
                margin-left: 10px;
            }

            &__subtitle {
                margin-left: 10px;
                line-height: 23px;
            }

            &__list {
                margin-top: 10px;
            }

            &__list-item {
                margin-left: 35px;
                margin-bottom: 5px;
                line-height: 21px;
            }
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        margin-bottom: 164px;

        &__content {
            max-width: 940px;
            height: 800px;
        }

        &__container {
            gap: 20px;
            grid-template-columns: repeat(2, 380px);
            grid-template-rows: repeat(6, 116.6px);
            margin-left: 160px;
        }

        &__title {
            left: -195px;
            top: 188px;
            line-height: 68px;
        }

        &__subtitle {
            line-height: 68px;
            left: -140px;
            top: 188px;
        }

        &__card {
            border-left: 40px solid $border_color;

            &:nth-child(5) {
                padding-top: 15px;
            }

            &:nth-child(6) {
                padding-top: 7px;
            }
        }

        &__cub {
            left: 40px;
        }

        .card {
            &__title {
                margin-top: 30px;
                margin-bottom: 15px;
                margin-left: 10px;
            }

            &__subtitle {
                margin-left: 10px;
                line-height: 19px;
            }

            &__list {
                margin-top: 10px;
            }

            &__list-item {
                margin-left: 35px;
                margin-bottom: 5px;
                line-height: 19px;
            }
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        margin-bottom: 134px;

        &__content {
            max-width: 640px;
            height: 1124px;
        }

        &__container {
            row-gap: 10px;
            column-gap: 20px;
            grid-template-columns: repeat(2, 255px);
            grid-template-rows: repeat(6, 179px);
            margin-left: 110px;
        }

        &__title {
            left: -195px;
            top: 188px;
            line-height: 68px;
        }

        &__subtitle {
            line-height: 68px;
            left: -140px;
            top: 188px;
        }

        &__card {
            border-left: 40px solid $border_color;

            &:nth-child(3) {
                grid-row: span 2;
                grid-column: 1/span 2;
            }

            &:nth-child(5) {
                padding-top: 35px;
                padding-right: 50px;
            }

            &:nth-child(6) {
                padding-top: 25px;
            }
        }

        &__cub {
            left: 40px;
        }

        .card {
            &__title {
                margin-top: 30px;
                margin-bottom: 15px;
                margin-left: 10px;
            }

            &__subtitle {
                margin-left: 10px;
                line-height: 19px;
                word-break: break-word;
            }

            &__list {
                margin-top: 10px;
            }

            &__list-item {
                margin-left: 35px;
                margin-bottom: 0;
                line-height: 19px;
            }
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        margin-bottom: 70px;

        &__content {
            max-width: 525px;
            height: 1010px;
        }

        &__container {
            row-gap: 10px;
            column-gap: 15px;
            grid-template-columns: repeat(2, 210px);
            grid-template-rows: repeat(6, 160px);
            margin-left: 89px;
        }

        &__title {
            left: -160px;
            top: 152px;
            line-height: 55px;
        }

        &__subtitle {
            line-height: 55px;
            left: -118px;
            top: 150px;
        }

        &__card {
            border-left: 20px solid $border_color;

            &:nth-child(3) {
                grid-row: span 2;
                grid-column: 1/span 2;
            }

            &:nth-child(5) {
                padding-top: 40px;
                padding-right: 50px;
            }

            &:nth-child(6) {
                padding-top: 30px;
            }
        }

        &__cub {
            display: none;
        }

        .card {
            &__title {
                margin-top: 20px;
                margin-bottom: 10px;
                margin-left: 10px;
            }

            &__subtitle {
                margin-left: 10px;
                line-height: 16px;
                word-break: break-word;
            }

            &__list {
                margin-top: 4px;
            }

            &__list-item {
                margin-left: 35px;
                margin-bottom: 5px;
                line-height: 16px;
            }
        }
    }

    @media screen and (min-width:320px) and (max-width:559px) {
        margin-bottom: 50px;

        &__content {
            max-width: 335px;
            height: 1625px;
        }

        &__container {
            row-gap: 10px;
            display: flex;
            flex-direction: column;
            margin-left: 89px;
        }

        &__title {
            left: -160px;
            top: 152px;
            line-height: 55px;
        }

        &__subtitle {
            line-height: 55px;
            left: -118px;
            top: 150px;
        }

        &__card {
            border-left: 20px solid $border_color;

            &:nth-child(1) {
                height: 304px;

            }

            &:nth-child(2) {
                height: 256px;

            }

            &:nth-child(3) {
                height: 576px;

            }

            &:nth-child(4) {
                height: 235px;

            }

            &:nth-child(5) {
                padding-top: 14px;
                padding-right: 50px;
                height: 102px;
            }

            &:nth-child(6) {
                padding-top: 0;
                height: 102px;
            }
        }

        &__cub {
            display: none;
        }

        .card {
            &__title {
                margin-top: 20px;
                margin-bottom: 10px;
                margin-left: 10px;
            }

            &__subtitle {
                margin-left: 10px;
                line-height: 16px;
                word-break: break-word;
            }

            &__list {
                margin-top: 4px;
            }

            &__list-item {
                margin-left: 35px;
                margin-bottom: 5px;
                line-height: 16px;
            }
        }
    }
}