.ControlSystems{
  h3,img{
    margin-bottom: 30px;
  }
  p{
    margin-bottom: 15px;
  }
  ul{
    margin: 30px 0 30px 20px;
  }
  li{
    font-weight: 400;
  }
  .flexContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__item{

    }
  }

  /*width: 300px;
  width: 300px;*/
  @media (min-width: 1280px) and (max-width: 1440px) {
    .flexContainer{
      &__item{
        max-width: 365px;
      }
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    img{
      max-width: 460px;
    }
    .flexContainer{
      &__item{
        max-width: 300px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    img{
      max-width: 460px;
    }
    .flexContainer{
      &__item{
        max-width: 300px;
      }
    }
  }
  @media (min-width: 560px) and (max-width: 767px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    h3,img{
      margin-bottom: 20px;
    }
    img{
      max-width: 345px;
    }
    .flexContainer{
      flex-direction: column;
      align-items: center;
      &__item{
        max-width: 300px;
      }
    }
  }
  @media (min-width: 375px) and (max-width: 559px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    h3,img{
      margin-bottom: 20px;
    }
    img{
      max-width: 335px;
    }
    .flexContainer{
      flex-direction: column;
      align-items: center;
      &__item{
        max-width: 300px;
      }
    }
  }
}