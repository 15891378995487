$bg_color:#F8F8F8;

.services-card {
    background: $bg_color;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    &:hover{
        filter: drop-shadow(2px 2px 30px rgba(45, 46, 67, 0.25));
    }
    &-big{
        grid-column: span 2;
        h4{
            max-width: 500px;
        }
    }

    &__link{
        text-decoration: none;
        color: black;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &__title {
        position: absolute;
        top: 20px;
        left: 15px;
        text-transform: uppercase;
        line-height: 29px;
        max-width: 300px;
    }

    &__img {
        position: absolute;
        right: 0;
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        &__title {
            max-width: 240px;
            line-height: 26px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        &__title {
            max-width: 220px;
            line-height: 23px;
        }

        &__img {
            height: 200px;
            right: -30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        &__title {
            max-width: 190px;
            word-break: break-word;
            line-height: 21px;
        }

        &__img {
            height: 160px;
            right: -105px;
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        &:nth-child(7) {
            word-break: break-word;
        }

        &__title {
            top: 10px;
            left: 5px;
            max-width: 168px;
            line-height: 19px;
            font-size: 16px !important;
        }

        &__img {
            height: 100px;
            right: -54px;
        }
    }
    @media screen and (min-width:320px) and (max-width:559px){
        &:last-child {
            grid-column: span 1;
            h4{
                max-width: 168px;
            }
            img{
                right: -125px; 
            }
        }
        &__title {
            top: 10px;
            left: 5px;
            max-width: 168px;
            line-height: 19px;
            font-size: 16px !important;
        }
        &__img {
            height: 100px;
            right: 0;
        }
    }
}