.call {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.8);
    transition: all 0.5s;
    z-index: 100;

    &__content {
        background: white;
        width: 1400px;
        height: 700px;
        position: relative;
        padding-top: 20px;
        overflow: hidden;

    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }

    &__disabled {
        opacity: 0;
        pointer-events: none;
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        &__content {
            width: 1200px;
            height: 750px;
            padding-top: 50px;
        }

        &__close {
            top: 30px;
            right: 30px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        &__content {
            width: 1000px;
            height: 620px;
            padding-top: 50px;
        }

        &__close {
            top: 30px;
            right: 30px;
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        &__content {
            width: 700px;
            height: 550px;
            padding-top: 50px;
            overflow: auto;
        }

        &__close {
            width: 20px;
            top: 20px;
            right: 20px;
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        &__content {
            width: 540px;
            height: 550px;
            padding-top: 0;
            overflow: auto;
        }

        &__close {
            width: 20px;
            top: 20px;
            right: 20px;
        }
    }

    @media screen and (min-width:320px) and (max-width:559px) {
        background: white;

        .form {
            height: 550px;
            background-size: 80%;
    background-position-x: -26px;
    background-position-y: 360px;

            &__heder {
                transform: rotate(360deg);
                top: -112px;
                left: 60px;
                text-align: center;
            }

            &__container {
                flex-direction: column;
                width: 90%;
                padding-top: 0;
                margin: 0 auto;
            }

            &__inputs {
                width: 100%;
            }

            &__connection {
                width: 100%;
            }

            &__textarea {
                width: 100%;
                height: 80px;
            }
        }

        &__content {
            width: 375px;
            height: 480px;
            padding-top: 0;
            overflow: visible;
            position: relative;
            top: 0;
        }

        &__close {
            width: 18px;
    top: -70px;
    right: 15px;
        }
    }
}