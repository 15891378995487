.MetalWorking{
  h3,img{
    margin-bottom: 15px;
    margin-right: 15px;
  }
  p{
    margin-bottom: 15px;
  }
  ul{
    margin: 30px 0 30px 20px;
  }
  li{
    font-weight: 400;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    img{
      max-width: 460px;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    img{
      max-width: 460px;
    }
  }
  @media (min-width: 560px) and (max-width: 767px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    h3,img{
      margin-bottom: 20px;
    }
    img{
      max-width: 345px;
    }
  }
  @media (min-width: 375px) and (max-width: 559px) {
    p{
      margin-bottom: 10px;
    }
    ul{
      margin: 20px 0 20px 20px;
    }
    h3,img{
      margin-bottom: 20px;
    }
    img{
      max-width: 335px;
    }
  }
}