@import url(./fonts/fonts.scss);
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App{
    position: relative;
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    h1{
        font-family: "Avenir Next";
        font-size: 70px;
        font-weight: bold;
    }
    h2{
        font-family: "Avenir Next";
        font-size: 45px;
        font-weight: bold; 
    }
    h3{
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-weight: 500;
    }
    h4{
        font-family: 'Roboto', sans-serif;
        font-size: 25px;
        font-weight: 300;
    }
    h5{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 300;
    }
    @media screen and (min-width:1280px) and (max-width:1440px) {
        h1{
            font-family: "Avenir Next";
            font-size: 60px;
            font-weight: bold;
        }
        h2{
            font-family: "Avenir Next";
            font-size: 35px;
            font-weight: bold; 
        }
        h3{
            font-family: 'Roboto', sans-serif;
            font-size: 28px;
            font-weight: 500;
        }
        h4{
            font-family: 'Roboto', sans-serif;
            font-size: 22px;
            font-weight: 300;
        }
        p{
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 400;
        } 
    }
    @media screen and (min-width:1024px) and (max-width:1279px){
        h1{
            font-family: "Avenir Next";
            font-size: 50px;
            font-weight: bold;
        }
        h2{
            font-family: "Avenir Next";
            font-size: 30px;
            font-weight: bold; 
        }
        h3{
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 500;
        }
        h4{
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            font-weight: 300;
        }
        p{
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 400;
        } 
    }
    @media screen and (min-width:768px) and (max-width:1023px) {
        h1{
            font-family: "Avenir Next";
            font-size: 50px;
            font-weight: bold;
        }
        h2{
            font-family: "Avenir Next";
            font-size: 30px;
            font-weight: bold; 
        }
        h3{
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 500;
        }
        h4{
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 300;
        }
        p{
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 400;
        } 
    }
    @media screen and (min-width:320px) and (max-width:767px){
        h1{
            font-family: "Avenir Next";
            font-size: 40px;
            font-weight: bold;
        }
        h2{
            font-family: "Avenir Next";
            font-size: 24px;
            font-weight: bold; 
        }
        h3{
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            font-weight: 500;
        }
        h4{
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 300;
        }
        h5{
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: 500;
        }
        p{
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
        } 
    }
}