.partners {
    max-width: 1290px;
    margin: 150px auto;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__link{
        text-decoration: none;
    }

    &__title {
        font-size: 112px !important;
        line-height: 153px;
        text-transform: uppercase;
        color: white;
        text-shadow:
            -0 -1px 0 #40E1EC,
            0 -1px 0 #40E1EC,
            -0 1px 0 #40E1EC,
            0 1px 0 #40E1EC,
            -1px -0 0 #40E1EC,
            1px -0 0 #40E1EC,
            -1px 0 0 #40E1EC,
            1px 0 0 #40E1EC,
            -1px -1px 0 #40E1EC,
            1px -1px 0 #40E1EC,
            -1px 1px 0 #40E1EC,
            1px 1px 0 #40E1EC,
            -1px -1px 0 #40E1EC,
            1px -1px 0 #40E1EC,
            -1px 1px 0 #40E1EC,
            1px 1px 0 #40E1EC;
    }

    &__subtitle {
        line-height: 96px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        position: relative;
        color: #40E1EC;
        top: -40px;
    }

    h3 {
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    h4 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 15px;
        max-width: 960px;
        font-weight: 300 !important;
    }

    &__info {
        margin: 15px 0;
    }

    ul {
        margin-bottom: 30px;
    }

    li {
        font-weight: bold;
        position: relative;
        left: 30px;
    }

    @media screen and (min-width:1280px) and (max-width:1440px) {
        max-width: 1170px;

        &__title {
            font-size: 105px !important;
            line-height: 143px;
        }
    }

    @media screen and (min-width:1024px) and (max-width:1279px) {
        max-width: 940px;
        margin: 100px auto;

        &__title {
            font-size: 86px !important;
            line-height: 117px;
        }

        h3 {
            margin-bottom: 20px;
        }

        p {
            max-width: 720px;
        }

        li {
            max-width: 720px;
        }
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
        margin: 100px auto;
        max-width: 640px;

        &__title {
            font-size: 86px !important;
            line-height: 117px;
        }

        h3 {
            margin-bottom: 20px;
        }

        p {
            max-width: 475px;
            margin-bottom: 10px;
        }

        ul {
            margin-bottom: 10px;
        }

        li {
            max-width: 475px;
        }
    }

    @media screen and (min-width:560px) and (max-width:767px) {
        margin: 50px auto;
        max-width: 525px;

        &__title {
            font-size: 44px !important;
            line-height: 60px;
        }

        &__subtitle {
            font-size: 30px !important;
            line-height: 41px;
            letter-spacing: -0.1em;
            top: -15px;
        }

        &__cub {
            height: 14px;
        }

        h3 {
            margin-bottom: 20px;
        }

        p {
            max-width: 500px;
            margin-bottom: 10px;
        }

        ul {
            margin-bottom: 10px;
        }

        li {
            max-width: 500px;
        }
    }

    @media screen and (min-width:320px) and (max-width:559px) {
        margin: 50px auto;
        max-width: 335px;

        &__title {
            font-size: 44px !important;
            line-height: 60px;
        }

        &__subtitle {
            font-size: 30px !important;
            line-height: 41px;
            letter-spacing: -0.1em;
            top: -15px;
        }

        &__cub {
            height: 14px;
        }

        h3 {
            margin-bottom: 20px;
        }

        p {
            max-width: 320px;
            margin-bottom: 10px;
        }

        ul {
            margin-bottom: 10px;
        }

        li {
            max-width: 320px;
        }
    }
}